import * as React from "react"
import { StyledHeader } from "../components/Elements"
import { PageWrapper } from "../components/PageWrapper"
import { Section } from "../components/Section"
import { StickyInfo } from "../components/StickyInfo"

// markup
const IndexPage = () => {
  return (
    <PageWrapper seo={{
      title: 'About'
    }}>
      <Section spacing={['mt-2']}>
        <StickyInfo />
        <StyledHeader>
            <h1 className="display">
              Share knowledge that might help others succeed.
            </h1>
            <div className="right font-size md">
            </div>
        </StyledHeader>
      </Section>
      <Section spacing={['mt-2']}>
        <StickyInfo />
        <StyledHeader>
            <h1 className="display">
              Share information that is proofed and tested.
            </h1>
            <div className="right font-size md">
            </div>
        </StyledHeader>
      </Section>
      <Section spacing={['mt-2']}>
        <StickyInfo />
        <StyledHeader>
            <h1 className="display">
              Provide examples so functionality can be easily copied.
            </h1>
            <div className="right font-size md">
            </div>
        </StyledHeader>
      </Section>
      <Section spacing={['mt-2']}>
        <StickyInfo />
        <StyledHeader>
            <h1 className="display">
              Spread the word in order to get opinions from others.
            </h1>
            <div className="right font-size md">
            </div>
        </StyledHeader>
      </Section>
    </PageWrapper>
  )
}

export default IndexPage